<template>
  <div class="page">
    <div class="container">
      <div class="title">
        <span class="class-i">首页</span>
        <span v-for="i in classList" :key="i.id"><span style="font-weight: bold" class="el-icon-arrow-right"></span><span class="class-i">{{i.name}}</span></span>
      </div>
      <div class="v-wrapper">
        <div class="mask" v-if="mask"><span><router-link class="login-btn" to="/login">登录</router-link></span>后可观看完整内容</div>
        <div v-loading="loading" ref="videoDom" class="video-wrapper">
          <video id="my-player" class="video-js vjs-big-play-centered" controls preload="auto">
          </video>
        </div>
        <div class="info-wrapper">
          <div>
            <p class="i-title bottom">{{video.name}}</p>
            <p class="sub-title">分集目录</p>
          </div>
          <!-- <p class="i-title">选集 <span class="tip">（共{{video.issue || '--'}}集）</span> </p> -->
          <div class="scroll-view">
            <div class="ablum-item" :class="{'active': currentPart === index}" v-for="(a, index) in parts" :key="a.id">
              <span :title="a.name" class="name">
                <span>{{a.name || (index > 8 ? (index + 1) : ('0' + (index + 1)))}}</span>
                <span class="active"></span>
              </span>
              <span class="try">
                <span @click="changePlay(a, index)" v-if="currentPart !== index">{{uname ? '播放' : '试看'}}</span>
                <span v-else class="living">
                  <span v-if="isLiving">播放中</span>
                  <span v-else>已暂停</span>
                </span>
              </span>
              <!-- <span @click="router.push('/login')" class="login" v-else>
                登录
              </span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="v-wrapper">
        <div class="video-info-l-wrapper">
          <div class="mobile">
            <p class="i-title v-title bottom">{{video.name}}</p>
          </div>
          <div class="hendler">
            <span class="mobile">
              <span class="h-item" :class="{active: current === 1}" @click="current = 1">视频简介</span>
              <span class="h-item" :class="{active: current === 2}" @click="current = 2">目录</span>
              <span class="h-item" v-if="!openMode" :class="{active: current === 3}" @click="current = 3">评论</span>
            </span>
            <span class="liked-list">
              <span class="liked-list" @click="like"><i :class="liked ? 'el-icon-star-on liked' : 'el-icon-star-off unlike'"> </i> <span>&nbsp;喜欢</span></span>
            </span>
            <!-- <el-rate @change="start" v-model="rate" :disabled="!!rate || !uname"></el-rate> -->
          </div>
          <div v-show="!isMobile || current === 1" class="">
            <p class="intro-title">视频简介</p>
            <div class="intro-wrapper">
              <div class="img-wrapper">
                <VideoImage class="image-wrapper" :src="video.coverImgUrl" />
              </div>
              <div class="content-wrapper">
                {{video.details}}
              </div>
            </div>
          </div>
          <div class="mobile" v-show="current === 2">
            <div class="scroll-view">
            <div class="ablum-item" :class="{'active': currentPart === index}" v-for="(a, index) in parts" :key="a.id">
              <span :title="a.name" class="name">
                <span>{{a.name || (index > 8 ? (index + 1) : ('0' + (index + 1)))}}</span>
                <span class="active"></span>
              </span>
              <span class="try">
                <span @click="changePlay(a, index)" v-if="currentPart !== index">{{uname ? '播放' : '试看'}}</span>
                <span v-else class="living">
                  <span v-if="isLiving">播放中</span>
                  <span v-else>已暂停</span>
                </span>
              </span>
            </div>
          </div>
          </div>
          <div v-show="!openMode && (!isMobile || current === 3)">
            <p class="intro-title">评论</p>
            <div class="comment-area">
              <!-- <img class="avatar" src="" alt=""> -->
              <span class="avatar"><span class="el-icon-user-solid"></span></span>
              <div class="comment-input">
                <span v-if="!uname" class="login-tip">请先<router-link class="login-btn" to="/login">登录</router-link></span>
                <textarea v-model.trim="words" :disabled="!uname" name="" id="" cols="30" placeholder="请输入您的评论..." rows="10"></textarea>
              </div>
              <span class="comment-btn">
                <el-button size="mini" :disabled="!words" type="primary" @click="comment()">发表评论</el-button>
              </span>
            </div>
            <div>
              <Comment v-for="c in commentList" :key="c.id" :comment="c" :uname="uname" @comment="content => comment(content, c.id)" />
            </div>
          </div>
        </div>
        <div class="video-info-r-wrapper">
          <div>
            <p :class="isMobile ? 'intro-title' : 'i-title'">相关推荐</p>
            <el-row :gutter="24">
              <el-col v-for="v in recommend" :key="v.id" :xs="{span: '12'}" :sm="{span: '24'}">
                <div class="v-w" >
                  <NewVideoItem @click="goVideo(v)" :width="300" :video="v" />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

    </div>

    <slot name="footer" />
  </div>
</template>

<script>
import useMixins from './mixins/media.mix'
import NewVideoItem from "@c/NewVideoItem.vue";
import VideoImage from "@c/VideoImage.vue";
import Comment from "@c/Comment.vue";
import { onBeforeUnmount, ref, toRefs, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getRealMedialUrl } from '@/util'

export default {
  name: "Video",
  props: {
    menuIdMap: Object,
  },
  components: {
    NewVideoItem,
    Comment,
    VideoImage
  },
  setup (props) {
    const player = ref(null)
    const state = ref(null)
    const videoDom = ref(null)
    const router = useRouter()
    const route = useRoute()
    const playTimer = ref(0)
    const openMode = ref(process.env.VUE_APP_RUN_MODE === 'open');

    const initBtn = () => {
      const wrapper = document.querySelector('.vjs-playback-rate')
      wrapper.style.width= '6em'
      const menu = wrapper.querySelector('.vjs-menu')
      menu.style.cssText = 'left: 50%;transform: translateX(-50%)'
      const text = wrapper.querySelector('.vjs-playback-rate-value')
      const parent = text.parentNode
      const clo = text.cloneNode()
      parent.removeChild(text)
      parent.appendChild(clo)
      clo.innerHTML = '画幅比'
      clo.addEventListener('click', (e) => {
        e.stopPropagation()
        e.preventDefault()
      }, true)
      clo.addEventListener('touchstart', (e) => {
        e.stopPropagation()
        e.preventDefault()
      }, true)
      const lis = [...wrapper.querySelectorAll('li')]
      lis[0].classList.add('vjs-selected')
      const rits = ['原始','16:9','4:3']
      lis.forEach((i, index) => {
        i.firstElementChild.innerHTML = rits[index]
        i.addEventListener('click', (e) => {
          wrapper.style.width= '4em'
          e.stopPropagation()
          e.preventDefault()
          lis.forEach(j => {
            j.classList.remove('vjs-selected')
          })
          i.classList.add('vjs-selected')
          clo.innerHTML = rits[index]
          fitVideo(index)
        }, true)
      })

    }

    const replaceHttps2Http = url => {
      if (url.includes('https://')) {
        return url.replace('https://', 'http://')
      } else {
        return url
      }
    }

    const fitVideo = (index) => {
      const videoWrapper = document.querySelector('.video-wrapper')
      const video = document.querySelector('.video-js')
      const v = document.querySelector('video')
      const height = videoWrapper.offsetHeight
      const width = videoWrapper.offsetWidth
      switch (index) {
        case 0:
          video.style.height = height + 'px'
          video.style.width = width + 'px'
          v.style = ''
          break;
        case 1:
          video.style.height = width * 0.5625 + 'px'
          video.style.width = width + 'px'
          v.style.objectFit = 'fill'
          break;
        case 2:
          video.style.height = height + 'px'
          video.style.width = height * 1.33333 + 'px'
          v.style.objectFit = 'fill'
          break;
        default:
          break;
      }
    }

    console.log(route, '000')
    const initVideo = (src, cb) => {
      setTimeout(() => {
        player.value = window.videojs('my-player', {
          controlBar: {
            children: [
              { name: 'playToggle' }, // 播放/暂停按钮
              { name: 'currentTimeDisplay' }, // 视频当前已播放时间
              { name: 'timeDivider' },
              { name: 'durationDisplay' }, // 视频播放总时间
              { name: 'progressControl' }, // 播放进度条
              window.innerWidth > 767 ? { // 倍数播放，可以自己设置
                name: 'playbackRateMenuButton',
                'playbackRates': ['16:9', '4:3', '原始']
              } : {},
              { // 倍数播放，可以自己设置
                name: 'playbackRateMenuButton',
                'playbackRates': [0.5, 1, 1.5, 2, 2.5]
              },
              {
                name: 'volumePanel', // 音量控制
                inline: false, // 不使用水平方式
              },
              { name: 'FullscreenToggle' } // 全屏
            ]
          },
          poster: getRealMedialUrl(state.value.video.coverImgUrl),
          src
        })
        player.value.src(src)
        // this.player.play()
        player.value.on('play', () => {
          state.value.isLiving = true
          state.value.mask = false
        })
        player.value.on('pause', () => {
          state.value.isLiving = false
        })
        const videoEl = player.value.children_[0]
        videoEl.setAttribute('webkit-playsinline', 'true')
        videoEl.setAttribute('playsinline', 'true')
        videoEl.setAttribute('x-webkit-airplay', 'true')
        videoEl.setAttribute('x5-video-player-type', 'h5')
        videoEl.setAttribute('x5-video-player-fullscreen', 'true')
        videoEl.setAttribute('x5-video-ignore-metadata', 'true')
        window.innerWidth > 767 && initBtn()


        cb && cb()
      })
    }
    const {
      state: _state,
      goVideos,
      changePlay,
      like,
      start,
      comment,
      goVideo,
      startListener,
      endListener
    } = useMixins(initVideo, player, props, videoDom, `<video id="my-player" class="video-js vjs-big-play-centered" controls preload="auto"></video>`)

    watchEffect(() => {
      player.value?.on('play', () => {
        clearTimeout(playTimer.value)
        playTimer.value = setTimeout(() => {
          startListener(_state.id)
        }, 1000)
      })
      player.value?.on('pause', () => {
        clearTimeout(playTimer.value)
        playTimer.value = setTimeout(() => {
          endListener(_state.id)
        }, 1000)
      })
    })

    state.value = _state

    onBeforeUnmount(() => {
      try {
        player.value?.dispose()
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
    })

    return {
      ...toRefs(_state),
      goVideos,
      changePlay,
      like,
      start,
      comment,
      goVideo,
      router,
      videoDom,
      isMobile: window.innerWidth < 768,
      openMode
    }
  }
};
</script>


<style scoped lang="stylus">

.title {
  color: #aaa;
  font-size: 15px;
  margin-top: 20px;
}

.v-wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  position: relative;

  & + .v-wrapper {
    margin-bottom: 20px;
  }
}

.video-wrapper {
  width: 77.8%;
  height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-info-l-wrapper {
  width: 77.8%;
}

.info-wrapper {
  display flex;
  flex-direction column;
  width: calc(22% - 25px);
  padding: 0 10px 10px 10px;
  // background: #eee;
  height: 500px;
  box-sizing: border-box;
}

.video-info-r-wrapper {
  width: calc(22% - 25px);
  padding-left: 20px;
  box-sizing: border-box;
}

::v-deep(.video-js) {
  width: 100%;
  height: 100%;
}

.i-title {
  // height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #444;

  & .tip {
    font-size: 14px;
  }
}

.ablum-item {
  height: 35px;
  line-height: 35px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  &.active {
    background: #fb888a;
    color: #fff;
  }
}

.name {
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.try, .login {
  color: #ef7c7d;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  width: 40px;
  text-align: right;
}

.login {
  color: #aaa;
}

.scroll-view {
  // height: calc(100% - 30px);
  flex 1;
  overflow: auto;
  // margin-bottom 10px
}

.hendler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  margin-bottom 10px

  span {
    cursor: pointer;
  }
}

.el-rate {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px;
}

.el-rate::after {
  content: '评分';
  vertical-align: middle;
}

.image-wrapper {
  max-width: 300px;
  margin: 0 10px 10px 0;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}

.intro-wrapper {
  padding-left: 50px;
  overflow: hidden;

  .img-wrapper {
    box-sizing: border-box;
    width: 200px;
    // height: 80px;
    float: left;
  }

  .content-wrapper {
  }
}

.intro-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 15px;
  color: #333;
}

.living {
  color: #fff;
}

.liked {
  color: #f20405;
}

.liked, .unlike {
  font-size: 16px;
}

.liked-list {
  display: flex;
  align-items: center;
}

.page {
  height: auto;
}

.v-w {
  margin-bottom: 20px;
  // box-shadow: 3px 2px 7px 0px rgba(0, 0, 0, 0.1);
}

.comment-area {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
}

.avatar {
  width: 50px;
  height: 50px;
  line-height 50px;
  font-size 28px;
  text-align center;
  color: #888;
  border-radius: 50%;
  background-color: #eee;
  margin-right: 20px;
}

.comment-input {
  flex: 1;
  position: relative;

  .login-tip {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 45%;
  }
}

textarea {
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  padding: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);

  &:focus {
    outline: none;
  }
}

.comment-btn {
  margin-left: 20px;
}


.m-video-info-r-wrapper {
  display: none;
}

.bottom {
  border-bottom 1px solid rgb(188, 161, 132);
  position relative;
  margin-bottom 20px
  font-weight bold
}
.bottom::before {
  content '';
  width 40%;
  border-bottom 3px solid rgb(174,97,102);
  display inline-block;
  position absolute;
  bottom -1px;
}
.sub-title {
  margin-bottom 15px
}
.mask {
  position: absolute;
  height: 500px;
  width: 800px;
  top: 0;
  background: rgba(0,0,0,.5);
  left: 0;
  z-index: 1000000;
  line-height 500px;
  text-align center;
  font-size 20px;
  color: #fff
}
.mask .login-btn {
  font-size 22px
  // height: 40px
  // line-height 40px
  padding: 10px
  border-radius 10px;
  margin-right 10px
}
.title {
  color: #272727;
}
@media screen and (max-width: 770px) {
  .video-wrapper {
    width: 70%;
    height: 320px;
  }
  .video-info-l-wrapper {
    width: 70%;
  }
  .info-wrapper {
    width: calc(30% - 10px);
    height: 320px;
  }
  .video-info-r-wrapper {
    width: calc(30% - 10px);
  }
  .name {
    width: calc(100% - 50px);
  }

  .try, .login {
    width: 50px;
  }
}

@media screen and (max-width: 767px) {
  .video-wrapper + .info-wrapper {
    display none
  }
  .video-wrapper {
    width: 100%
  }
  .video-info-l-wrapper,.video-info-r-wrapper {
    width: 100%;
    padding-left 0
  }
  .v-wrapper + .v-wrapper {
    display: block
  }
  .h-item {
    border: 2px solid #383d42;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    padding: 0px 10px;
    border-radius: 13px;
    box-sizing: border-box;
    font-size 14px;
    color: #383d42;
    margin-right 18px;
    box-sizing: content-box;
    font-family: '微软雅黑';
    letter-spacing: 1px;
    &.active {
      color: #9e2020;
      border-color #9e2020
    }
  }
  .liked-list {
    color: #383d42;
    font-family: '微软雅黑';
  }
  .intro-wrapper {
    padding-left: 0;
  }
  .scroll-view {
    max-height: 320px;
    margin-bottom 10px
  }
  .mask {
    width: 100%;
    height: 100%;
    line-height: 320px;
  }
  .v-title {
    font-size 19px
  }
  .intro-title {
    font-size 17px
  }
  .content-wrapper {
    font-size 15px
  }
  .v-wrapper {
    font-size 15px
  }
}
</style>
